import './styles/normalize.css';
import './styles/animation.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-mobile-cropper/dist/style.css'
import Stages from "./stages/Stages";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {MainContextProvider} from "./ContextState";
import * as Sentry from "@sentry/react";
import LogRocket from 'logrocket';
import Wrapper from "./Wrapper";
import Resizer from "./common/Resizer";


if (process.env.NODE_ENV === "production") {
    LogRocket.init('uamlxw/frame-prod');

    Sentry.init({
        dsn: "https://50b3aadf166e5fb1ea01fa2aaaad8931@o4506189547044864.ingest.sentry.io/4506189554188288",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", "mebook"],
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

function App() {
    return (
        <MainContextProvider>
            <div>
                <Router>
                    <Routes>
                        <Route element={<Resizer><Wrapper/></Resizer>}>
                            <Route path="/" element={<Stages/>}/>
                        </Route>
                    </Routes>
                </Router>
            </div>
        </MainContextProvider>
    );
}

export default App;