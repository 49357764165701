import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { getRelevantTemplateImage, getRelevantTemplateTitle } from "../../common/utils";
import PlotModal from "./PlotModal";

const Wrapper = styled.div`

`;

const TitleWrapperStyled = styled.div`
    background: rgba(000, 000, 000, 0.5);
    height: 60px;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: grid;
    align-items: center;

    .title {
        //bottom: 0;
        color: #fff;
        font-size: 24px;
        //margin-inline-start: 10px;
        //margin-bottom: 5px;
        //position: absolute;
        z-index: 2;
    }
`;

const ItemsWrapper = styled.div`
    max-width: 100%;
    padding: 1%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
`;

const ItemStyled = styled.div`
    display: inline-block;
    margin: 0 7px;
    width: 270px;
    height: 270px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    border: 4px solid transparent;

    img {
        //border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.selected {
        border: 4px solid #FFC43D;
    }

    .info {
        top: 34px
    }
`;


const MobileSlider = ({ selectedItem, items, lang, langData, progress, selectedTemplate, getItemAnimation }) => {
    const [openSelection, setOpened] = useState(false);
    const [closeModalForce, s_closeModalForce] = useState(false);
    const [selectedPlotItem, s_selectedPlotItem] = useState(null);

    const divRefs = useRef([]);

    useEffect(() => {
        if (selectedItem) {
            const selectedItemIndex = items.findIndex(item => item._id === selectedItem);
            if (divRefs.current[selectedItemIndex]) {
                divRefs.current[selectedItemIndex].scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                });
            }
        }
    }, [selectedItem, items]);

    const openSelectionFunc = () => {
        setOpened(!openSelection);
        s_closeModalForce(false);
    };

    return (
        <Wrapper>
            <ItemsWrapper>
                { items.map((item, itemIndex) => {
                    const title = getRelevantTemplateTitle(item, progress);
                    const imageToShow = getRelevantTemplateImage(item, progress);

                    const isSelected = selectedItem === item._id;
                    return (

                        <ItemStyled
                            ref={ el => (divRefs.current[itemIndex] = el) }
                            tabIndex={ isSelected ? "0" : "-1" }
                            onClick={ () => selectedTemplate(item) }
                            key={ item._id }
                            className={ isSelected ? "selected poikop" : "poikop" }
                        >
                            <div
                                onKeyDown={ (e) => {
                                    if (e.key === " " || e.key === "Enter") {
                                        s_selectedPlotItem(item);
                                        openSelectionFunc(e);
                                    }
                                } }
                                tabIndex={ isSelected ? "0" : "-1" }
                                onClick={
                                    event => {
                                        openSelectionFunc();
                                        s_selectedPlotItem(item);
                                    }
                                }
                                className="info"
                                aria-label={ `${ langData.arias.more_info_about_template } ${ title }` }
                            />
                            <motion.div { ...getItemAnimation(0.2 * itemIndex, false) }>
                                { (isSelected ? <div className="selectedItem"></div> : "") }
                                <img src={ imageToShow } className="template-img" alt="template"/>
                                <TitleWrapperStyled>
                                    <div className="title">{ title }</div>
                                </TitleWrapperStyled>
                            </motion.div>
                        </ItemStyled>

                    );
                }) }
            </ItemsWrapper>

            { openSelection && (
                <PlotModal
                    progress={ progress }
                    selectedPlotItem={ selectedPlotItem }
                    openSelectionFunc={ openSelectionFunc }
                    lang={ lang }
                    closeModalForce={ closeModalForce }
                    ariaTitle={ langData.arias.templateInfoDialogTitle }
                />
            ) }
        </Wrapper>
    );
};

export default MobileSlider;
