import React, {useEffect, useRef} from 'react';
import styled from "styled-components";

const WrapperStyled = styled.div`
    display: block;

    label {
        border: 1px solid #CACACA;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
        display: block;
        position: relative;
    }

    input, textarea {
        display: block;
        width: 100%;
        box-sizing: border-box;
    }

    input, span, textarea {
        font-size: 20px;
        padding: 8px 6px;
        border: 0;
        outline: none;
        border-radius: 4px;
    }

    span {
        display: inline-block;
        position: absolute;
        margin: 0;
        color: transparent;
        transition: all 0.1s ease-in-out;
        right: 18px;
        top: -18px;
    }

    label:focus-within {
        border-color: #FFC43D;
    }

    label:focus-within span,
    label input:not(:placeholder-shown) + span,
    label textarea:not(:placeholder-shown) + span {
        font-size: 14px;
        background-color: white;
        padding: 0 4px;
        color: black;
        margin: 0;
        right: 18px;
        top: -8px;
    }

    label textarea:focus + span,
    label textarea:not(:placeholder-shown) + span {
        margin-top: -96px;
    }

    &.invalid {
        label {
            border: 1px solid #FF0000FF;
        }
    }
`;

const CustomInput = ({
                         value,
                         onBlur,
                         onChange,
                         type = 'text',
                         id = '',
                         label = '',
                         inputmode = 'text',
                         isInvalid = false,
                         children,
                         ariaRequired,
                         errormessage,
                         ariaDescribedby,
                         onFocus,
                         reff
                     }) => {

    const reffd = useRef(reff);

    return (
        <WrapperStyled className={isInvalid ? 'invalid' : ""}>
            <label>
                <input
                    ref={reffd}
                    aria-describedby={ariaDescribedby}
                    aria-errormessage={errormessage}
                    aria-required={ariaRequired}
                    onBlur={(e) => onBlur ? onBlur(e) : null}
                    onFocus={(e) => onFocus ? onFocus(e) : null}
                    inputMode={inputmode}
                    value={value}
                    onChange={onChange}
                    aria-label={label}
                    type={type} placeholder={label}/>
                <span>{label}</span>
            </label>
            {children}
        </WrapperStyled>
    );
};

export default CustomInput;
