import axios from 'axios';

// const API_URL = 'http://localhost:5001/api/';
// const API_URL = 'http://172.16.10.188:5001/api/';
const API_URL = 'https://api.mebook.ai/api/';

export const getBooksCovers = async (id, lang) => {
    const response = await axios.get(`${API_URL}template?categoryId=${id}&lang=${lang}`, {});
    return response.data.data;
};

export const setSelectCover = async (formData) => {
    const response = await axios.post(`${API_URL}frame/select-cover`, formData);
    // const response = await axios.post(`http://localhost:4001/api/frame/select-cover`, formData);
    return response.data.data; // coverImageBookThumbUrl
};


export const getBooksCoversAll = async (lang, clientId) => {
    const response = await axios.get(`${API_URL}frame/templates?language=${lang}&clientId=${clientId}`, {});
    return response.data.data;
};


export const getBookCategories = async () => {
    const response = await axios.get(`${API_URL}frame/categories`, {});
    return response.data.data;
};


export const uploadFile = async (data) => {
    const response = await axios.post(`${API_URL}frame/upload-face`, data);
    return response.data.data;
};

export const uploadFileV2 = async (data) => {
    const response = await axios.post(`${API_URL}frame/upload-face-v2`, data);
    return response.data;
};

export const uploadFileV3 = async (data) => {
    const response = await axios.post(`${API_URL}frame/upload-face-v3`, data);
    return response.data;
};

export const removePrebookApi = async (prebookId) => {
    const response = await axios.delete(`${API_URL}frame/recent-prebooks/${prebookId}/remove`);
    return response.data;
};

export const getParams = async () => {
    const response = await axios.get(`${API_URL}frame/params`, {});
    return response.data.data;
};

export const initBookApi = async (endUserEmail, clientId) => {
    const response = await axios.post(`${API_URL}frame/initBook`, {endUserEmail, clientId});
    return response.data.data;
};

export const initBookApiV2 = async (endUserEmail, clientId, faceImageUrl, croppedFaceImageUrl, endUserPhone, eyeglasses, uniqueId) => {
    const response = await axios.post(`${API_URL}frame/initBook-v2`, {
        endUserEmail,
        endUserPhone,
        clientId,
        faceImageUrl,
        croppedFaceImageUrl,
        eyeglasses,
        userUniqueId: uniqueId
    });
    return response.data;
};

export const createBook = async (data) => {
    const response = await axios.post(`${API_URL}frame/startBook`, data);
    return response.data.data;
};

export const saveBook = async (data) => {
    const response = await axios.post(`${API_URL}frame/save-book`, data);
    return response.data.data;
};

export const bookStatus = async (bookId) => {
    const response = await axios.post(`${API_URL}frame/status`, {
        bookId: bookId
    });
    return response.data.data;
};

export const getConfig = async (lang, clientId) => {
    const response = await axios.get(`${API_URL}frame/config?lang=${lang}&clientId=${clientId}`);
    return response.data.data;
};

export const getBooksByEmail = async (email) => {
    const response = await axios.post(`${API_URL}frame/get-books-by-email`, {
        email: email
    });
    return response.data.data;
};

export const getPrebooks = async (phone) => {
    const response = await axios.post(`${API_URL}frame/recent-prebooks`, {
        phone
    });
    return response.data.data;
};
