import {motion} from "framer-motion";
import {getRelevantTemplateImage, getRelevantTemplateTitle, transformImage} from "../../../common/utils";
import styled from "styled-components";


const TitleWrapperStyled = styled.div`
    background: rgba(000, 000, 000, 0.5);
    height: 40px;
    position: absolute;
    bottom: 0;
    width: 100%;
`;

function BookBlock({item, selectedBookTMP, selectedItem, getItemAnimation, itemIndex, onPlotShow, progress, langData}) {

    const selectedTemplate = (item) => {
        selectedBookTMP(item);
    };

    const onInfoHandle = (e) => {
        e.stopPropagation();
        onPlotShow(item);
    };


    const title = getRelevantTemplateTitle(item, progress);
    const imageToShow =  getRelevantTemplateImage(item, progress);

    const isSelected = selectedItem === item._id;
    return (
        <motion.div {...getItemAnimation(0.2 * itemIndex, false)}
                    className={`template_item ${selectedItem === item._id ? 'selectedContainer' : ''}`}
                    onClick={() => selectedTemplate(item)}
        >
            <div className="imageBox">
                <div
                    onKeyDown={(e) => {
                        if (e.key === " " || e.key === "Enter") {
                            onInfoHandle(e);
                        }
                    }}
                    tabIndex={isSelected ? "0" : "-1"}
                    onClick={onInfoHandle}
                    className="info"
                    aria-label={`${langData.arias.more_info_about_template} ${title}`}
                />
                {(isSelected ? <div className="selectedItem"></div> : '')}
                <img src={imageToShow} className="template-img" alt="template"/>
                <TitleWrapperStyled>
                    <div className="title">{title}</div>
                </TitleWrapperStyled>
            </div>
        </motion.div>);
}

export default BookBlock;