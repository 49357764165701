import eng from "../languages/eng";
import heb from "../languages/heb";
import ch from "../languages/ch";

export const chooseLang = (lang) => {
    switch(lang) {
        case 'en':
            return eng();
        case 'he':
            return heb();
        case 'zh':
            return ch();
    }
}
