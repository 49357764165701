import React, {useContext, useEffect, useState} from 'react';
import {Outlet, useLocation} from "react-router-dom";
import {getClientIdCookie, getStageCookie} from "./common/cookie";
import {getConfig} from "./ApiCalls";
import {MainContext} from "./ContextState";
import styled from "styled-components";
import NotActive from "./stages/components/NotActive";
import Loader from "./stages/components/Loader";

const WraperStyled = styled.div`
    margin: auto;
    text-align: center;

    .loader-o {
        margin-top: 20px;
    }
`;

const Wrapper = () => {
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || 'he';
    const clientIdQueryParams = queryParameters.get("clientId") || '64d491633684ef0d32edb473';
    const cookieClientId = getClientIdCookie();

    const [loadingConfig, s_loadingConfig] = useState(true);
    const [isActive, s_isActive] = useState(false);

    const {configContext, clientIdContext} = useContext(MainContext);
    const [, s_config] = configContext;
    const [, s_clientId] = clientIdContext;

    useEffect(() => {
        const relevantClientId = cookieClientId || clientIdQueryParams;

        s_clientId(relevantClientId);
        getConfig(lang, relevantClientId).then(data => {
            s_config(data);
            s_loadingConfig(false);
            if (data.isActive) {
                s_isActive(true);
            }
        }).catch(e => {
            console.log('e', e);
        });
    }, []);

    const loading = loadingConfig;

    return (
        <div>
            {loading && (
                <WraperStyled>
                    <Loader/>
                </WraperStyled>
            )}
            {!loading && isActive && (
                <Outlet/>
            )}

            {!loading && !isActive && (
                <NotActive/>
            )}
        </div>
    );
};

export default Wrapper;