import logo from "../images/logo.svg";
import {BackSvg} from "../../svg";
import {useContext} from "react";
import {MainContext} from "../../ContextState";
import ProgressBar2 from "./ProgressBar2";


function Header({loading, showLogo, langData, isBookReady, setFocusToProgress}) {
    const {stageNumberContext} = useContext(MainContext);
    const [currentStage, s_currentStage] = stageNumberContext;
    const goBack = () => {
        if(currentStage === 4){
            s_currentStage(currentStage - 2);
        }else {
            s_currentStage(currentStage - 1);
        }
    };

    let showBackButton = true;
    if(currentStage === 1){
        showBackButton = false;
    }
    if(showBackButton && isBookReady){
        showBackButton = false;
    }

    return (
        <div className="header">
            <ProgressBar2
                langData={langData}
                setFocusToProgress={setFocusToProgress}
            />
            {showLogo && (
                <div className="logo-container">
                    <div className="logo"><img src={logo} alt="logo"/></div>
                </div>
            )}
            {showBackButton && (
                <button
                    aria-label={langData.arias.back_button}
                    // tabIndex="0"
                    className="back-btn" onClick={() => goBack()}>
                    <BackSvg/>
                </button>
            )}
        </div>
    );
}

export default Header;
