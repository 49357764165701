export const WarningSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                fill="#FFC43D"/>
        </svg>
    )
}

export const BackSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="24" viewBox="0 0 13 24" fill="none">
            <path d="M11.8125 1.375L1.1875 12L11.8125 22.625" stroke="black" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

export const CloseButtonSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M25.3332 8.5465L23.4532 6.6665L15.9998 14.1198L8.5465 6.6665L6.6665 8.5465L14.1198 15.9998L6.6665 23.4532L8.5465 25.3332L15.9998 17.8798L23.4532 25.3332L25.3332 23.4532L17.8798 15.9998L25.3332 8.5465Z" fill="black"/>
        </svg>
    )
}

export const ArrowRightButtonSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="23" viewBox="0 0 13 23" fill="none">
            <path d="M1 22.25L11.625 11.625L1 1" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ArrowLeftButtonSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="23" viewBox="0 0 13 23" fill="none">
            <path d="M11.625 1L1 11.625L11.625 22.25" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const UploadMediaSvg = () =>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path
                d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z"/>
        </svg>
    )
}

export const ClickMeArrow = () =>{
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M8.60112 16.5308C8.89426 16.8234 9.36913 16.823 9.66178 16.5299C9.95442 16.2367 9.95402 15.7619 9.66088 15.4692L8.60112 16.5308ZM5.52988 11.3452C5.23674 11.0526 4.76187 11.053 4.46922 11.3461C4.17658 11.6393 4.17698 12.1141 4.47012 12.4068L5.52988 11.3452ZM4.48682 11.3291C4.18475 11.6125 4.16964 12.0871 4.45306 12.3892C4.73648 12.6912 5.21111 12.7064 5.51318 12.4229L4.48682 11.3291ZM9.64418 8.54694C9.94625 8.26352 9.96136 7.78889 9.67794 7.48682C9.39452 7.18475 8.91989 7.16964 8.61782 7.45306L9.64418 8.54694ZM5 11.126C4.58579 11.126 4.25 11.4618 4.25 11.876C4.25 12.2902 4.58579 12.626 5 12.626V11.126ZM15.37 11.876L15.3723 11.126H15.37V11.876ZM17.9326 10.8234L18.4614 11.3553V11.3553L17.9326 10.8234ZM19.75 8.26907C19.7511 7.85486 19.4163 7.51815 19.0021 7.517C18.5879 7.51586 18.2511 7.85072 18.25 8.26493L19.75 8.26907ZM9.66088 15.4692L5.52988 11.3452L4.47012 12.4068L8.60112 16.5308L9.66088 15.4692ZM5.51318 12.4229L9.64418 8.54694L8.61782 7.45306L4.48682 11.3291L5.51318 12.4229ZM5 12.626H15.37V11.126H5V12.626ZM15.3677 12.626C16.5267 12.6295 17.6395 12.1724 18.4614 11.3553L17.4038 10.2916C16.8641 10.8282 16.1333 11.1283 15.3723 11.126L15.3677 12.626ZM18.4614 11.3553C19.2833 10.5382 19.7468 9.42801 19.75 8.26907L18.25 8.26493C18.2479 9.02598 17.9435 9.755 17.4038 10.2916L18.4614 11.3553Z"
                    fill="#FFA927"></path>
            </g>
        </svg>
    )
}

export const ArrowLeftSvg = () => {
  return (
      <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM26 7H23.9167V9H26V7ZM19.75 7H15.5833V9L19.75 9V7ZM11.4167 7L7.25 7V9H11.4167V7ZM3.08334 7L1 7V9H3.08334V7ZM0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM26 7H23.9167V9H26V7ZM19.75 7H15.5833V9L19.75 9V7ZM11.4167 7L7.25 7V9H11.4167V7ZM3.08334 7L1 7V9H3.08334V7Z"
              fill="#FFB02A"/>
      </svg>
  )
}

export const VArrowSvg = () => {
    return (
        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.54961 13L0.849609 7.29998L2.27461 5.87498L6.54961 10.15L15.7246 0.974976L17.1496 2.39998L6.54961 13Z"
                fill="white"/>
        </svg>
    )
}

export const XArrowSvg = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.8573 0.982337L12.0176 0.1427L6.49993 5.66035L0.982289 0.1427L0.142578 0.982337L5.66026 6.50002L0.142578 12.0177L0.982289 12.8573L6.49993 7.33969L12.0176 12.8573L12.8573 12.0177L7.33961 6.50002L12.8573 0.982337Z"
                fill="white"/>
        </svg>
    )
}

export const WarningSvg2 = () => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.1665 12.8333H23.8332V16.5H20.1665V12.8333ZM20.1665 20.1667H23.8332V31.1667H20.1665V20.1667ZM21.9998 3.66667C11.8798 3.66667 3.6665 11.88 3.6665 22C3.6665 32.12 11.8798 40.3333 21.9998 40.3333C32.1198 40.3333 40.3332 32.12 40.3332 22C40.3332 11.88 32.1198 3.66667 21.9998 3.66667ZM21.9998 36.6667C13.9148 36.6667 7.33317 30.085 7.33317 22C7.33317 13.915 13.9148 7.33334 21.9998 7.33334C30.0848 7.33334 36.6665 13.915 36.6665 22C36.6665 30.085 30.0848 36.6667 21.9998 36.6667Z"
                fill="#FFC43D"/>
        </svg>

    )
}

export const UploadIconSvg2 = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M48.5226 57.9217H6.45839V15.8575H33.4996V9.84834H6.45839C3.15334 9.84834 0.449219 12.5525 0.449219 15.8575V57.9217C0.449219 61.2267 3.15334 63.9308 6.45839 63.9308H48.5226C51.8276 63.9308 54.5317 61.2267 54.5317 57.9217V30.8804H48.5226V57.9217ZM25.1168 48.3971L19.2279 41.3063L10.9653 51.9125H44.0157L33.3795 37.7609L25.1168 48.3971ZM54.5317 9.84834V0.834595H48.5226V9.84834H39.5088C39.5388 9.87839 39.5088 15.8575 39.5088 15.8575H48.5226V24.8412C48.5526 24.8713 54.5317 24.8412 54.5317 24.8412V15.8575H63.5455V9.84834H54.5317Z"
                fill="white"/>
        </svg>

    )
}

export const UploadIconSvg3 = () => {
  return (
      <svg viewBox="0 0 1024 1024" className="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#ffc43d">
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
              <path
                  d="M768 810.7c-23.6 0-42.7-19.1-42.7-42.7s19.1-42.7 42.7-42.7c94.1 0 170.7-76.6 170.7-170.7 0-89.6-70.1-164.3-159.5-170.1L754 383l-10.7-22.7c-42.2-89.3-133-147-231.3-147s-189.1 57.7-231.3 147L270 383l-25.1 1.6c-89.5 5.8-159.5 80.5-159.5 170.1 0 94.1 76.6 170.7 170.7 170.7 23.6 0 42.7 19.1 42.7 42.7s-19.1 42.7-42.7 42.7c-141.2 0-256-114.8-256-256 0-126.1 92.5-232.5 214.7-252.4C274.8 195.7 388.9 128 512 128s237.2 67.7 297.3 174.2C931.5 322.1 1024 428.6 1024 554.7c0 141.1-114.8 256-256 256z"
                  fill="#ffc43d"></path>
              <path
                  d="M640 789.3c-10.9 0-21.8-4.2-30.2-12.5L512 679l-97.8 97.8c-16.6 16.7-43.7 16.7-60.3 0-16.7-16.7-16.7-43.7 0-60.3l128-128c16.6-16.7 43.7-16.7 60.3 0l128 128c16.7 16.7 16.7 43.7 0 60.3-8.4 8.4-19.3 12.5-30.2 12.5z"
                  fill="#ffc43d"></path>
              <path
                  d="M512 960c-23.6 0-42.7-19.1-42.7-42.7V618.7c0-23.6 19.1-42.7 42.7-42.7s42.7 19.1 42.7 42.7v298.7c0 23.5-19.1 42.6-42.7 42.6z"
                  fill="#ffc43d"></path>
          </g>
      </svg>
  )
}