import React, {useContext, useEffect, useRef} from 'react';
import styled from "styled-components";
import {MainContext} from "../../ContextState";

const WrapperStyled = styled.div`
    width: 400px;
    padding: 4px;
    background: #F0F0F0;
    border-radius: 6px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
    height: 8px;

    @media (max-width: 420px) {
        width: 100%;
        margin: 4px 3%;
        
    }
`;

const StepsWrapperStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
`;

const StepStyled = styled.div`
    width: 100%;
    height: 7px;
    background: #FFC43D;
    border-radius: 5px;
    transition: width 1s ease-in-out;
`;

const steps = [1, 2, 4];

const ProgressBar2 = ({langData, setFocusToProgress}) => {
    const ref = useRef(null);
    const {stageNumberContext} = useContext(MainContext);
    const [currentStage] = stageNumberContext;

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, [currentStage]);

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, [setFocusToProgress]);

    let ariaLabel = `${langData?.arias?.stepper?.start} ${currentStage} ${langData?.arias?.stepper?.end} 4 ${langData?.arias?.levels?.level}`;
    if (currentStage === 1) {
        ariaLabel += ` ${langData?.arias?.levels?.upload_image}`
    }
    if (currentStage === 2) {
        ariaLabel += ` ${langData?.arias?.levels?.fill_info}`
    }
    if (currentStage === 3) {
        ariaLabel += ` ${langData?.arias?.levels?.choose_appearance}`
    }
    if (currentStage === 4) {
        ariaLabel += ` ${langData?.arias?.levels?.choose_template}`
    }

    return (
        <WrapperStyled
            ref={ref}
            tabIndex="0"
            role="heading"
            aria-label={ariaLabel}
        >
            <StepsWrapperStyled>
                {steps.map(step => {
                    let width = '100%';
                    if (currentStage <= step) {
                        width = 0;
                    }

                    return (
                        <StepStyled
                            style={{width}}
                            key={step}
                        />
                    )
                })}
            </StepsWrapperStyled>
        </WrapperStyled>
    );
};

export default ProgressBar2;